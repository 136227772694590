@import url("https://fonts.googleapis.com/css?family=Manrope");
@import "./tachyons.min.css";

html,
body {
  background: #ffff00;
  height: 100%;
}

body {
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

nav {
  border-color: #111;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: black;
}
